$text-fg: rgb(73, 73, 73)
$text-fg-primary: rgb(55, 115, 219)
$text-fg-danger: rgb(250, 5, 62)
$text-fg-focus: $text-fg-primary
$text-fg-mute: rgb(197, 197, 197)

$window-bg: rgba(20, 60, 112, .18)

$border-bg: rgb(173, 173, 173)

$tab-padding: .42rem
$tab-border-bg: $border-bg

$anim-fast: 280ms
$anim-slow: 720ms
$anim-veryslow: 1260ms

=no-text-select
  user-select: none
  cursor: default
