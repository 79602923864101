@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i,900&subset=latin-ext')

//------------------------------------------------------------------------------

@import '~css-reset-and-normalize-sass'

//------------------------------------------------------------------------------

@import '../node_modules/animate.css'

.animated
  animation-duration: .377s

//------------------------------------------------------------------------------

$flag-icon-css-path: './../node_modules/flag-icon-css/flags/'
@import '~flag-icon-css/sass/flag-icon.scss'

//------------------------------------------------------------------------------

$fa-font-path: './../node_modules/font-awesome/fonts/'
@import '~font-awesome/scss/font-awesome'

//------------------------------------------------------------------------------

@import './theme'

//------------------------------------------------------------------------------

html, body
  width: 100%
  height: 100%
  overflow: hidden

body
  font-family: 'Lato', sans-serif
  font-size: 14px
  color: $text-fg
  background-image: url('./static/logo-bg.png')
  background-repeat: no-repeat
  background-position: center center
// overflow: auto
// -webkit-overflow-scrolling: touch

a
  color: inherit
  text-decoration: none

  :active
    outline: none

input, textarea, button, select
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  color: $text-fg
  line-height: 160%
  outline: none
  background-color: white
  border-radius: 0
  border: 1px solid $border-bg
  padding: 0.15em 0.5em

input, textarea, select, button
  transition: border-color $anim-fast ease

  &:focus
    outline: 2px solid $text-fg-focus

  &:hover
    border-color: $text-fg-focus

  &[readonly]
    background: rgba(white, .5)

  &[disabled='disabled'], &:disabled
    color: $text-fg-mute
    border-color: $text-fg-mute
    &:hover
      color: $text-fg-mute
      border-color: $text-fg-mute

=_placeholder
  font-size: 0.8em
  font-weight: lighter
  text-align: right

::-webkit-input-placeholder
  +_placeholder

::-moz-placeholder
  +_placeholder

button
  +no-text-select
  transition: color $anim-fast ease
  border-color: $text-fg
  transition: border-color $anim-fast ease, color $anim-fast ease

  &:hover
    color: $text-fg-primary

//------------------------------------------------------------------------------

.fg-primary
  color: $text-fg-primary

.fg-danger
  color: $text-fg-danger

.fg-mute
  color: $text-fg-mute

//------------------------------------------------------------------------------

.flex-1
  flex: 1

.flex-0-1
  flex: 0 1

.flex-1-0
  flex: 1 0

//------------------------------------------------------------------------------

.context-menu
  position: absolute
  right: 0
  top: 0
  z-index: 7000
  background: white

//------------------------------------------------------------------------------

.link
  +no-text-select
  cursor: pointer
  color: $text-fg
  transition: color $anim-fast ease

  &:hover
    color: $text-fg-primary

//------------------------------------------------------------------------------

.window
  background-color: $window-bg

//.window-shadow
// border-radius: 3px
// box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, .6)
//  box-sizing: border-box
//  border-right: 1px solid $border-bg
//  border-bottom: 1px solid $border-bg
//  box-shadow: 0 0 13px 6px $window-bg

//------------------------------------------------------------------------------

.button-row
  text-align: right

  button
    margin-left: 0.5em
    margin-right: 0.5em

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0

//------------------------------------------------------------------------------

.tabs
  +no-text-select
  display: table
  font-size: 0.6rem

  .tab-separator
    width: 3rem

  .tab
    display: table-cell
    transition: background-color $anim-veryslow ease, color $anim-fast ease, border-color $anim-fast ease
    padding: $tab-padding
    padding-left: 1rem
    border-right: 1px solid $tab-border-bg
    cursor: pointer

    &:hover
      color: $text-fg-primary
      border-color: $text-fg-primary
      //background-color: $window-bg-hover

    &:first-child
      padding-left: $tab-padding

    &:last-child
      border-right-color: transparent

    &.active
      background-color: $window-bg

    &.no-hover
      &:hover
        background-color: inherit

    &.no-border
      border-right: 0

    &.disabled
      color: $text-fg-mute
      pointer-events: none
      &:hover
        color: $text-fg-mute
        border-color: $text-fg-mute

    .icon
      font-size: 2em
      margin-right: 0.5em

//------------------------------------------------------------------------------

.action-bar
  +no-text-select
  text-align: right
  padding-right: 0.5rem

  .action
    cursor: pointer
    color: $text-fg
    transition: color $anim-fast ease
    margin-right: 0.5em

    &:last-child
      margin-right: 0

    &:hover
      color: $text-fg-primary

    &.danger
      &:hover
        color: $text-fg-danger

    &.primary
      &:hover
        color: $text-fg-primary

    &.disabled
      pointer-events: none
      color: $text-fg-mute
      &:hover
        color: $text-fg-mute

//------------------------------------------------------------------------------

.table
  width: 100%
  font-size: 1em
  line-height: 220%
  border-collapse: separate
  border-spacing: 0 0.5em

  thead
    text-align: left

  th
    color: $text-fg-mute

  td, th
    &:first-child
      padding-left: 1em

    &:last-child
      padding-right: 1em

  tr
    &:nth-child(odd)
      td
        background: $window-bg
    &:nth-child(even)
      td
        background: darken($window-bg, 20%)

  .selector
    +no-text-select
    line-height: 200%
    cursor: pointer
    padding-left: 0.5rem
    transition: border-color 230ms ease
    color: $text-fg-primary

    &:hover
      color: lighten($text-fg-primary, 20%)

//------------------------------------------------------------------------------

.unsupported-browser
  text-align: center
  color: $text-fg-danger
  margin-top: 10%
  background: rgba(white, 0.9)

//------------------------------------------------------------------------------

.app
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  overflow: hidden
  padding-top: 32px

  .view
    width: 100%
    height: 100%

  .viewfade-enter-active, .viewfade-leave-active
    transition: opacity .377s ease

  .viewfade-enter, .viewfade-leave-active
    opacity: 0
